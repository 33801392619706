import { defineInjectionTokenMetadata } from "../reflection-helpers";
function injectAllWithTransform(token, transformer) {
    var args = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        args[_i - 2] = arguments[_i];
    }
    var data = {
        token: token,
        multiple: true,
        transform: transformer,
        transformArgs: args
    };
    return defineInjectionTokenMetadata(data);
}
export default injectAllWithTransform;
